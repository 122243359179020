import React from "react"
import { DesktopWrapper, LogoWrapper, MobileWrapper } from "./header.css"

const Logo = () => {
  return (
    <LogoWrapper to="/">
      <DesktopWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 245.95 146.023">
  <g id="SPEvents_Stacked_Black_-_Transparent-01_11_" data-name="SPEvents_Stacked_Black - Transparent-01[11]" transform="translate(-49.95 -43.777)">
    <path id="Path_12086" data-name="Path 12086" d="M73.1,155a.8.8,0,0,1,.9.9V157a.845.845,0,0,1-.2.6c-.1.2-.4.2-.7.2H52.9v12H69.1a.845.845,0,0,1,.6.2.956.956,0,0,1,.2.7v1.2a.845.845,0,0,1-.2.6c-.1.2-.4.2-.7.2H52.9v13.6H73.3a.8.8,0,0,1,.9.9v1.2c0,.6-.3.8-.9.8H50.8a.764.764,0,0,1-.5-.2.845.845,0,0,1-.2-.6V155.9a1.445,1.445,0,0,1,.2-.7,1.421,1.421,0,0,1,.6-.2Z"/>
    <path id="Path_12087" data-name="Path 12087" d="M97.3,185c.5-1.3,1.1-2.8,1.8-4.3.7-1.6,1.3-3.2,2-4.9s1.4-3.5,2.2-5.2c.7-1.8,1.5-3.5,2.2-5.3.7-1.7,1.4-3.4,2.1-5s1.3-3.1,1.8-4.5c.1-.2.1-.3.2-.4s.1-.2.3-.2h1.5c.5.1.7.3.7.7v.2c0,.1,0,.2-.1.2L98.5,188.8a1.38,1.38,0,0,1-.3.4.845.845,0,0,1-.6.2H97a.856.856,0,0,1-.9-.7L82.6,156.5c0-.1-.1-.2-.1-.3a4.332,4.332,0,0,1-.1-.5c0-.3.2-.5.7-.6h1c.3,0,.5,0,.6.1a1.082,1.082,0,0,1,.4.5q.9,2.1,1.8,4.5c.7,1.6,1.4,3.3,2.1,5s1.5,3.5,2.2,5.3,1.5,3.5,2.2,5.3c.7,1.7,1.4,3.4,2.1,4.9.6,1.5,1.2,3,1.8,4.3Z"/>
    <path id="Path_12088" data-name="Path 12088" d="M146.2,155a.8.8,0,0,1,.9.9V157a.845.845,0,0,1-.2.6c-.1.2-.4.2-.7.2H126.1v12h16.2a.845.845,0,0,1,.6.2.956.956,0,0,1,.2.7v1.2a.845.845,0,0,1-.2.6c-.1.2-.4.2-.7.2H126v13.6h20.4a.8.8,0,0,1,.9.9v1.2c0,.6-.3.8-.9.8H123.8a.764.764,0,0,1-.5-.2.845.845,0,0,1-.2-.6V155.9a1.445,1.445,0,0,1,.2-.7,1.421,1.421,0,0,1,.6-.2Z"/>
    <path id="Path_12089" data-name="Path 12089" d="M184.1,155.8a.736.736,0,0,1,.8-.8h1c.5,0,.8.2.8.7v32.7c0,.5-.3.8-.9.8h-1.1a.91.91,0,0,1-.8-.4l-23.2-29.4v28.9c0,.6-.3.8-.9.8h-1c-.6,0-.8-.3-.8-.8v-33c0-.1.1-.2.3-.2a5.638,5.638,0,0,1,.7-.1h1.2a.52.52,0,0,1,.4.2c1.8,2.3,3.7,4.7,5.7,7.1,2,2.5,3.9,5,5.9,7.5l6,7.5c2,2.5,3.9,4.8,5.7,7.1l.2-28.6Z"/>
    <path id="Path_12090" data-name="Path 12090" d="M223,155a.845.845,0,0,1,.6.2.6.6,0,0,1,.3.6v.9a.845.845,0,0,1-.2.6.908.908,0,0,1-.7.3H212.3v30.7a.8.8,0,0,1-.9.9h-.9a.8.8,0,0,1-.9-.9V157.6H198.8c-.6,0-.8-.3-.8-.9v-.9a.707.707,0,0,1,.8-.8Z"/>
    <path id="Path_12091" data-name="Path 12091" d="M233.6,183.1c.3-.3.6-.5.8-.7a1.136,1.136,0,0,1,.8-.3.52.52,0,0,1,.4.2l.3.3a12.448,12.448,0,0,0,2.1,1.9,12.294,12.294,0,0,0,2.5,1.4,14.262,14.262,0,0,0,2.7.9,12.413,12.413,0,0,0,2.7.3,15.534,15.534,0,0,0,3.7-.4,11.961,11.961,0,0,0,3.3-1.3,7.216,7.216,0,0,0,2.3-2.3,6.508,6.508,0,0,0,.9-3.5,4.638,4.638,0,0,0-.4-2.1,6.1,6.1,0,0,0-1.2-1.6,8.06,8.06,0,0,0-1.8-1.2c-.7-.3-1.4-.6-2.2-.9-.8-.2-1.6-.5-2.4-.7a20.483,20.483,0,0,0-2.4-.5,38.76,38.76,0,0,1-5-1.4,12.785,12.785,0,0,1-3.5-1.9,7.91,7.91,0,0,1-2.1-2.4,6.172,6.172,0,0,1-.7-3.1,9.213,9.213,0,0,1,.8-3.8,9.551,9.551,0,0,1,2.4-2.9,10.643,10.643,0,0,1,3.7-1.8,18.794,18.794,0,0,1,4.9-.6,15.553,15.553,0,0,1,5.7,1,13.669,13.669,0,0,1,4.8,3.1c.2.2.3.4.4.5s.1.2.1.4a.758.758,0,0,1-.2.4l-.7.7c-.1.1-.3.2-.3.3a.367.367,0,0,1-.3.1,1.2,1.2,0,0,1-.8-.4,10.918,10.918,0,0,0-1.7-1.3,11.783,11.783,0,0,0-1.9-1.1,15.545,15.545,0,0,0-2.3-.8,12.65,12.65,0,0,0-2.8-.3,14.787,14.787,0,0,0-3.9.5,7.368,7.368,0,0,0-2.8,1.4,5.65,5.65,0,0,0-1.7,2.1,5.422,5.422,0,0,0-.6,2.5,3.425,3.425,0,0,0,.4,1.8,4.508,4.508,0,0,0,1,1.4,8.277,8.277,0,0,0,1.5,1.1c.6.3,1.2.6,1.9.9a14.162,14.162,0,0,0,2.2.7c.8.2,1.5.4,2.3.6.9.2,1.8.4,2.8.7a13.087,13.087,0,0,1,2.8.9,20.618,20.618,0,0,1,2.6,1.2,17.684,17.684,0,0,1,2.2,1.6,8.008,8.008,0,0,1,1.5,2.1,6.361,6.361,0,0,1,.5,2.8,8.677,8.677,0,0,1-1,4.4,9.541,9.541,0,0,1-2.8,3.2,11.056,11.056,0,0,1-4.1,1.9,20.116,20.116,0,0,1-5,.7,18.7,18.7,0,0,1-3.4-.3,11.909,11.909,0,0,1-3.2-1,16.924,16.924,0,0,1-2.9-1.7,14.771,14.771,0,0,1-2.5-2.5l-.3-.3c-.1-.1-.1-.2-.1-.4-.2-.3-.1-.4,0-.5Z"/>
    <path id="Path_12092" data-name="Path 12092" d="M127.5,127.3a5.716,5.716,0,0,0-.8.7,12.91,12.91,0,0,1-5.2,3.2,16.826,16.826,0,0,1-6.3.8,15.489,15.489,0,0,1-6.6-2c-4.8-2.9-7.3-7.4-7.4-13.4a10.972,10.972,0,0,1,.5-3.5,14.85,14.85,0,0,1,9.4-10.5,14.446,14.446,0,0,1,4.2-.9,18.678,18.678,0,0,1,4.6.3,14.665,14.665,0,0,1,7,3.8h0a1.794,1.794,0,0,0,.7.5,1.21,1.21,0,0,0,1.4-.2,1.247,1.247,0,0,0,.3-1.2,1.538,1.538,0,0,0-.4-.6c-.4-.4-.8-.7-1.3-1.2-2.6-2.2-7.1-5-15.4-3.5a15.651,15.651,0,0,0-9.4,5.7,17.413,17.413,0,0,0-4,11.6,14.874,14.874,0,0,0,.7,4.7,17.266,17.266,0,0,0,6.4,9.3,17.622,17.622,0,0,0,10.4,3.3c1,0,2-.1,3-.2a17.044,17.044,0,0,0,9.4-4.6c.2-.2.4-.3.5-.5a1.17,1.17,0,0,0-1.7-1.6Z"/>
    <path id="Path_12093" data-name="Path 12093" d="M115.2,71.9a5.716,5.716,0,0,0-.8.7,12.91,12.91,0,0,1-5.2,3.2,16.826,16.826,0,0,1-6.3.8,15.489,15.489,0,0,1-6.6-2c-4.8-2.9-7.3-7.4-7.4-13.4a10.972,10.972,0,0,1,.5-3.5,14.85,14.85,0,0,1,9.4-10.5,14.446,14.446,0,0,1,4.2-.9,18.678,18.678,0,0,1,4.6.3,14.665,14.665,0,0,1,7,3.8h0a1.794,1.794,0,0,0,.7.5,1.21,1.21,0,0,0,1.4-.2,1.247,1.247,0,0,0,.3-1.2,1.538,1.538,0,0,0-.4-.6c-.4-.4-.8-.7-1.3-1.2-2.6-2.2-7.1-5-15.4-3.5a16.919,16.919,0,0,0-9.5,5.9,17.413,17.413,0,0,0-4,11.6,14.874,14.874,0,0,0,.7,4.7,17.266,17.266,0,0,0,6.4,9.3A17.622,17.622,0,0,0,103.9,79c1,0,2-.1,3-.2a17.044,17.044,0,0,0,9.4-4.6c.2-.2.4-.3.5-.5a1.347,1.347,0,0,0-.1-1.6,1.084,1.084,0,0,0-1.5-.2Z"/>
    <path id="Path_12094" data-name="Path 12094" d="M198.3,99.3a.8.8,0,0,1,.9.9v1.2a.845.845,0,0,1-.2.6c-.1.2-.4.2-.7.2H177.7v12.3h16.6a.845.845,0,0,1,.6.2,1.165,1.165,0,0,1,.2.7v1.2a.845.845,0,0,1-.2.6c-.1.2-.4.2-.7.2H177.6v13.9h20.9a.8.8,0,0,1,.9.9v1.2c0,.6-.3.8-.9.8H175.4a.764.764,0,0,1-.5-.2.845.845,0,0,1-.2-.6V100a1.445,1.445,0,0,1,.2-.7,1.421,1.421,0,0,1,.6-.2l22.8.2Z"/>
    <path id="Path_12095" data-name="Path 12095" d="M50.1,72.5c.3-.3.6-.5.8-.7a1.136,1.136,0,0,1,.8-.3.52.52,0,0,1,.4.2l.3.3a12.449,12.449,0,0,0,2.1,1.9A12.294,12.294,0,0,0,57,75.3a14.262,14.262,0,0,0,2.7.9,12.413,12.413,0,0,0,2.7.3,15.534,15.534,0,0,0,3.7-.4,11.961,11.961,0,0,0,3.3-1.3,7.216,7.216,0,0,0,2.3-2.3,6.508,6.508,0,0,0,.9-3.5,4.638,4.638,0,0,0-.4-2.1A6.1,6.1,0,0,0,71,65.3a8.06,8.06,0,0,0-1.8-1.2c-.7-.3-1.4-.6-2.2-.9-.8-.2-1.6-.5-2.4-.7a20.48,20.48,0,0,0-2.4-.5,38.761,38.761,0,0,1-5-1.4,12.785,12.785,0,0,1-3.5-1.9,7.91,7.91,0,0,1-2.1-2.4,6.172,6.172,0,0,1-.7-3.1,9.213,9.213,0,0,1,.8-3.8,9.551,9.551,0,0,1,2.4-2.9,10.643,10.643,0,0,1,3.7-1.8,18.794,18.794,0,0,1,4.9-.6,15.553,15.553,0,0,1,5.7,1,13.67,13.67,0,0,1,4.8,3.1c.2.2.3.4.4.5s.1.2.1.4a.758.758,0,0,1-.2.4l-.7.7c-.1.1-.3.2-.3.3a.367.367,0,0,1-.3.1,1.2,1.2,0,0,1-.8-.4,10.916,10.916,0,0,0-1.7-1.3,11.781,11.781,0,0,0-1.9-1.1,15.546,15.546,0,0,0-2.3-.8,12.65,12.65,0,0,0-2.8-.3,14.787,14.787,0,0,0-3.9.5A7.368,7.368,0,0,0,56,48.6a5.65,5.65,0,0,0-1.7,2.1,5.421,5.421,0,0,0-.6,2.5,3.424,3.424,0,0,0,.4,1.8,4.508,4.508,0,0,0,1,1.4,8.277,8.277,0,0,0,1.5,1.1c.6.3,1.2.6,1.9.9a14.161,14.161,0,0,0,2.2.7c.8.2,1.5.4,2.3.6.9.2,1.8.4,2.8.7a13.087,13.087,0,0,1,2.8.9,20.619,20.619,0,0,1,2.6,1.2,17.684,17.684,0,0,1,2.2,1.6,8.008,8.008,0,0,1,1.5,2.1,6.361,6.361,0,0,1,.5,2.8,8.677,8.677,0,0,1-1,4.4,9.541,9.541,0,0,1-2.8,3.2,11.056,11.056,0,0,1-4.1,1.9,20.116,20.116,0,0,1-5,.7,18.7,18.7,0,0,1-3.4-.3,11.909,11.909,0,0,1-3.2-1A16.924,16.924,0,0,1,53,76.2a14.771,14.771,0,0,1-2.5-2.5l-.3-.3c-.1-.1-.1-.2-.1-.4-.2-.3-.2-.4,0-.5Z"/>
    <path id="Path_12096" data-name="Path 12096" d="M198.7,44a.845.845,0,0,1,.6.2.713.713,0,0,1,.3.6v1a1.445,1.445,0,0,1-.2.7.908.908,0,0,1-.7.3H187.8V78.2a.8.8,0,0,1-.9.9h-1a.8.8,0,0,1-.9-.9V46.7H174c-.6,0-.8-.3-.8-.9v-1a.845.845,0,0,1,.2-.6,1.072,1.072,0,0,1,.6-.2Z"/>
    <path id="Path_12097" data-name="Path 12097" d="M234.7,44a.845.845,0,0,1,.6.2.713.713,0,0,1,.3.6v1a1.445,1.445,0,0,1-.2.7.908.908,0,0,1-.7.3H223.8V78.2a.8.8,0,0,1-.9.9h-1a.8.8,0,0,1-.9-.9V46.7H210c-.6,0-.8-.3-.8-.9v-1a.845.845,0,0,1,.2-.6,1.072,1.072,0,0,1,.6-.2Z"/>
    <path id="Path_12098" data-name="Path 12098" d="M234.9,99.3a.845.845,0,0,1,.6.2.713.713,0,0,1,.3.6v1a1.445,1.445,0,0,1-.2.7.908.908,0,0,1-.7.3H224v31.4a.8.8,0,0,1-.9.9h-1a.8.8,0,0,1-.9-.9V102.1h-11c-.6,0-.8-.3-.8-.9v-1a.845.845,0,0,1,.2-.6,1.072,1.072,0,0,1,.6-.2h24.7Z"/>
    <path id="Path_12099" data-name="Path 12099" d="M270.4,99.3a.845.845,0,0,1,.6.2.713.713,0,0,1,.3.6v1a1.445,1.445,0,0,1-.2.7.908.908,0,0,1-.7.3H259.5v31.4a.8.8,0,0,1-.9.9h-1a.8.8,0,0,1-.9-.9V102.1h-11c-.6,0-.8-.3-.8-.9v-1a.845.845,0,0,1,.2-.6,1.072,1.072,0,0,1,.6-.2h24.7Z"/>
    <path id="Path_12100" data-name="Path 12100" d="M85.4,99.4h1a.9.9,0,0,1,.9.9v33.2a.9.9,0,0,1-.9.9h-1a.9.9,0,0,1-.9-.9V100.3A.9.9,0,0,1,85.4,99.4Z"/>
    <path id="Path_12101" data-name="Path 12101" d="M164,132.3l-.6-.5h0C162.6,131,147,116,147,116a1.047,1.047,0,0,1-.3-.6c0-.3.4-.6.4-.6l15.5-13.2a.823.823,0,0,0,.1-1.2l-.6-.7a.823.823,0,0,0-1.2-.1l-15.6,13.5c-.3.3-.7.6-1,.9-.1.1-.8.4-.8-.4V100.2a.9.9,0,0,0-.9-.9h-1a.9.9,0,0,0-.9.9v33.3a.9.9,0,0,0,.9.9h1a.9.9,0,0,0,.9-.9v-16c0-1.2.7-.7.8-.6l.7.6,17.3,16.7a.912.912,0,0,0,1.2,0l.6-.6a1.213,1.213,0,0,0-.1-1.3Z"/>
    <path id="Path_12102" data-name="Path 12102" d="M163.6,60.6A17.573,17.573,0,1,0,146,79h.7a17.619,17.619,0,0,0,12.4-5.7,17.892,17.892,0,0,0,4.5-12.7Zm-2.6.9a15,15,0,0,1-14.9,14.9H146A14.354,14.354,0,0,1,135.5,72a14.929,14.929,0,0,1-4.4-10.5A14.852,14.852,0,0,1,146,46.7h.1A14.788,14.788,0,0,1,161,61.5Z"/>
    <path id="Path_12103" data-name="Path 12103" d="M50.1,100.4a1.032,1.032,0,0,1,1.1-1.1H62.4a9.9,9.9,0,0,1,4.2.9,14.147,14.147,0,0,1,3.5,2.3,8.568,8.568,0,0,1,2.3,3.5,10.25,10.25,0,0,1,0,8.4,11.809,11.809,0,0,1-2.3,3.5,9.756,9.756,0,0,1-3.4,2.3,11.683,11.683,0,0,1-4.2.9H52.9v12.1a1,1,0,0,1-1.1,1.1h-.6c-.7,0-1-.4-1-1.1l-.1-32.8Zm20.4,9.8a8.662,8.662,0,0,0-.6-3.2,6.765,6.765,0,0,0-1.8-2.6,8.061,8.061,0,0,0-2.6-1.8,8.662,8.662,0,0,0-3.2-.6H52.7v16.6h9.6a8.662,8.662,0,0,0,3.2-.6,6.765,6.765,0,0,0,2.6-1.8,12.029,12.029,0,0,0,1.8-2.6,9.737,9.737,0,0,0,.6-3.4Z"/>
    <path id="Path_12104" data-name="Path 12104" d="M279.3,133.5c2.1-5.2,4.2-10.5,6.4-15.7,2.1-5.2,4.3-10.5,6.4-15.8.2-.5.4-.9.6-1.3a3.916,3.916,0,0,1,.8-1,2.537,2.537,0,0,1,1.2-.2,2.22,2.22,0,0,1,.9.1.462.462,0,0,1,.3.5.6.6,0,0,1-.1.4c-2.3,5.6-4.6,11.2-6.8,16.7-2.2,5.6-4.5,11.1-6.8,16.7-.2.4-.4.6-.8.6h-1.7c-.4,0-.7-.2-.7-.5.2-.2.2-.4.3-.5Z"/>
  </g>
</svg>

      </DesktopWrapper>
      <MobileWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="141"  viewBox="0 0 141 15.892">
  <g id="Group_5879" data-name="Group 5879" transform="translate(-30 -32.794)">
    <path id="Path_11613" data-name="Path 11613" d="M424.726,160.841q.206-.206.339-.319a.508.508,0,0,1,.34-.113.288.288,0,0,1,.2.072.879.879,0,0,1,.134.144,5.9,5.9,0,0,0,.952.86,6.017,6.017,0,0,0,1.1.633,6.234,6.234,0,0,0,1.189.391,5.571,5.571,0,0,0,1.2.133,7.463,7.463,0,0,0,1.652-.185,4.689,4.689,0,0,0,1.467-.592,3.284,3.284,0,0,0,1.05-1.045,2.84,2.84,0,0,0,.4-1.544,2.079,2.079,0,0,0-.2-.931,2.255,2.255,0,0,0-.55-.71,3.6,3.6,0,0,0-.813-.525,7.594,7.594,0,0,0-.983-.386q-.52-.165-1.065-.293t-1.06-.242a16.7,16.7,0,0,1-2.228-.648,6.327,6.327,0,0,1-1.58-.834,3.143,3.143,0,0,1-.937-1.081,3.051,3.051,0,0,1-.309-1.4,3.809,3.809,0,0,1,.376-1.708,3.692,3.692,0,0,1,1.066-1.3,4.989,4.989,0,0,1,1.667-.823,7.728,7.728,0,0,1,2.182-.288,7.077,7.077,0,0,1,2.547.458,6.15,6.15,0,0,1,2.146,1.395c.089.089.156.16.2.211a.242.242,0,0,1,.067.16.3.3,0,0,1-.1.19q-.1.108-.309.3c-.062.055-.113.1-.155.134a.19.19,0,0,1-.123.052.644.644,0,0,1-.361-.2q-.36-.308-.756-.586a5.432,5.432,0,0,0-.87-.494,5.314,5.314,0,0,0-1.045-.344,5.777,5.777,0,0,0-1.271-.129,6.006,6.006,0,0,0-1.76.232,3.79,3.79,0,0,0-1.255.633,2.59,2.59,0,0,0-1.009,2.069,1.923,1.923,0,0,0,.164.818,2.115,2.115,0,0,0,.458.639,3.043,3.043,0,0,0,.689.494,6.951,6.951,0,0,0,.855.381q.458.17.963.3t1.009.247q.6.145,1.24.309t1.261.386a8.741,8.741,0,0,1,1.168.515,4.126,4.126,0,0,1,.968.7,2.984,2.984,0,0,1,.9,2.223,4.063,4.063,0,0,1-1.688,3.417,5.591,5.591,0,0,1-1.837.87,8.659,8.659,0,0,1-3.783.155,6.378,6.378,0,0,1-1.436-.443,6.2,6.2,0,0,1-2.4-1.888.948.948,0,0,1-.113-.155.323.323,0,0,1-.041-.165A.229.229,0,0,1,424.726,160.841Z" transform="translate(-394.633 -115.109)" fill="#1a1818"/>
    <path id="Path_11614" data-name="Path 11614" d="M465.065,149.053a.457.457,0,0,1,.473-.473h4.879a4.6,4.6,0,0,1,1.853.376,4.789,4.789,0,0,1,2.527,2.527,4.76,4.76,0,0,1,0,3.705,4.8,4.8,0,0,1-2.519,2.527,4.525,4.525,0,0,1-1.835.376h-4.194v5.3a.42.42,0,0,1-.478.473h-.249q-.457,0-.457-.473Zm8.944,4.282a3.537,3.537,0,0,0-.283-1.41,3.745,3.745,0,0,0-.765-1.152,3.54,3.54,0,0,0-1.141-.777,3.5,3.5,0,0,0-1.4-.283h-4.173v7.246h4.173a3.494,3.494,0,0,0,1.4-.283,3.54,3.54,0,0,0,1.141-.777,3.741,3.741,0,0,0,.765-1.152A3.536,3.536,0,0,0,474.009,153.335Z" transform="translate(-418.268 -115.385)" fill="#1a1818"/>
    <path id="Path_11615" data-name="Path 11615" d="M499.826,163.408l2.779-6.876q1.39-3.437,2.8-6.885a4.313,4.313,0,0,1,.283-.581,2.294,2.294,0,0,1,.355-.458,1.358,1.358,0,0,1,.545-.1,1.092,1.092,0,0,1,.4.057.2.2,0,0,1,.139.2.592.592,0,0,1-.041.2q-1.5,3.664-2.97,7.307t-2.959,7.3a.359.359,0,0,1-.35.257.306.306,0,0,1-.093-.01h-.648q-.289-.031-.288-.226A.406.406,0,0,1,499.826,163.408Z" transform="translate(-438.557 -115.341)" fill="#1a1818"/>
    <g id="Group_5878" data-name="Group 5878" transform="translate(47 -24.907)">
      <path id="Path_11616" data-name="Path 11616" d="M436.575,208.013a.345.345,0,0,1,.391.388v.511a.4.4,0,0,1-.1.281.393.393,0,0,1-.3.107h-8.985v5.373H434.8a.434.434,0,0,1,.283.088.356.356,0,0,1,.108.293v.525a.35.35,0,0,1-.391.381h-7.225v6.062H436.7a.341.341,0,0,1,.391.381v.535a.332.332,0,0,1-.381.371H426.62a.327.327,0,0,1-.235-.088.361.361,0,0,1-.091-.273V208.394a.369.369,0,0,1,.1-.294.411.411,0,0,1,.276-.088Z" transform="translate(-395.604 -150.127)" fill="#1a1818"/>
      <path id="Path_11617" data-name="Path 11617" d="M467.76,221.419q.361-.886.8-1.941t.911-2.2q.473-1.148.973-2.346t.978-2.357q.479-1.158.921-2.239t.823-2.007a1.533,1.533,0,0,1,.087-.185.179.179,0,0,1,.119-.083,1.067,1.067,0,0,1,.242-.02h.468q.319.041.319.3c0,.014,0,.044-.005.093a.153.153,0,0,1-.026.093l-6.052,14.532a.794.794,0,0,1-.123.2.362.362,0,0,1-.278.083h-.3a.412.412,0,0,1-.412-.3l-6.021-14.389a1.06,1.06,0,0,1-.052-.139.706.706,0,0,1-.031-.211c0-.137.1-.223.309-.257h.453a.662.662,0,0,1,.273.046.438.438,0,0,1,.18.222q.371.926.818,2.007t.931,2.244l.983,2.362q.5,1.2.978,2.352t.921,2.207Q467.389,220.534,467.76,221.419Z" transform="translate(-415.951 -150.143)" fill="#1a1818"/>
      <path id="Path_11618" data-name="Path 11618" d="M515.308,208.013a.344.344,0,0,1,.391.388v.511a.4.4,0,0,1-.1.281.393.393,0,0,1-.3.107h-8.985v5.373h7.225a.435.435,0,0,1,.283.088.357.357,0,0,1,.108.293v.525a.35.35,0,0,1-.391.381h-7.225v6.062h9.119a.341.341,0,0,1,.391.381v.535a.332.332,0,0,1-.381.371H505.353a.326.326,0,0,1-.235-.088.361.361,0,0,1-.092-.273V208.394a.369.369,0,0,1,.1-.294.41.41,0,0,1,.275-.088Z" transform="translate(-441.627 -150.127)" fill="#1a1818"/>
      <path id="Path_11619" data-name="Path 11619" d="M554.336,208.363a.331.331,0,0,1,.371-.35h.463q.35,0,.35.33v14.625a.331.331,0,0,1-.381.34h-.484a.417.417,0,0,1-.339-.165L543.941,210v12.938a.332.332,0,0,1-.381.371h-.432a.328.328,0,0,1-.371-.371V208.394a.911.911,0,0,1,.021-.222.159.159,0,0,1,.118-.108,1.192,1.192,0,0,1,.3-.036q.2,0,.551,0a.244.244,0,0,1,.2.1q1.214,1.533,2.532,3.19t2.661,3.34L551.8,218q1.323,1.657,2.537,3.191Z" transform="translate(-463.684 -150.127)" fill="#1a1818"/>
      <path id="Path_11620" data-name="Path 11620" d="M596.789,208.013a.422.422,0,0,1,.283.093.413.413,0,0,1,.129.278v.422a.414.414,0,0,1-.1.288.4.4,0,0,1-.309.113h-4.776v13.72a.337.337,0,0,1-.381.381h-.422a.337.337,0,0,1-.381-.381v-13.72h-4.817q-.371,0-.371-.4v-.422a.34.34,0,0,1,.371-.371Z" transform="translate(-488.752 -150.127)" fill="#1a1818"/>
      <path id="Path_11621" data-name="Path 11621" d="M623.79,220.3q.206-.206.339-.319a.509.509,0,0,1,.34-.113.288.288,0,0,1,.2.072.863.863,0,0,1,.134.144,5.9,5.9,0,0,0,.952.86,6.018,6.018,0,0,0,1.1.633,6.234,6.234,0,0,0,1.189.391,5.6,5.6,0,0,0,1.2.133,7.463,7.463,0,0,0,1.652-.185,4.69,4.69,0,0,0,1.467-.592,3.284,3.284,0,0,0,1.05-1.045,2.84,2.84,0,0,0,.4-1.544,2.079,2.079,0,0,0-.2-.931,2.255,2.255,0,0,0-.55-.71,3.6,3.6,0,0,0-.813-.525,7.591,7.591,0,0,0-.983-.386q-.52-.164-1.065-.293t-1.06-.242a16.7,16.7,0,0,1-2.228-.648,6.328,6.328,0,0,1-1.58-.834,3.143,3.143,0,0,1-.937-1.081,3.051,3.051,0,0,1-.309-1.4,3.809,3.809,0,0,1,.376-1.708,3.692,3.692,0,0,1,1.066-1.3,4.988,4.988,0,0,1,1.667-.823,7.729,7.729,0,0,1,2.182-.288,7.077,7.077,0,0,1,2.547.458,6.139,6.139,0,0,1,2.146,1.395c.089.089.156.16.2.211a.242.242,0,0,1,.067.16.3.3,0,0,1-.1.19q-.1.108-.309.3c-.062.055-.113.1-.155.134a.192.192,0,0,1-.123.052.644.644,0,0,1-.361-.2q-.36-.308-.756-.586a5.43,5.43,0,0,0-.87-.494,5.345,5.345,0,0,0-1.045-.345,5.8,5.8,0,0,0-1.271-.129,6.007,6.007,0,0,0-1.76.231,3.79,3.79,0,0,0-1.255.634,2.59,2.59,0,0,0-1.009,2.069,1.923,1.923,0,0,0,.164.818,2.116,2.116,0,0,0,.458.639,3.045,3.045,0,0,0,.689.494,7.018,7.018,0,0,0,.855.381q.458.17.963.3t1.009.247q.6.145,1.24.309t1.261.386a8.737,8.737,0,0,1,1.168.515,4.126,4.126,0,0,1,.968.7,2.984,2.984,0,0,1,.9,2.223,4.063,4.063,0,0,1-1.688,3.417,5.591,5.591,0,0,1-1.837.87,8.659,8.659,0,0,1-3.783.155,6.373,6.373,0,0,1-1.436-.443,6.206,6.206,0,0,1-2.4-1.888.947.947,0,0,1-.113-.155.323.323,0,0,1-.041-.165A.229.229,0,0,1,623.79,220.3Z" transform="translate(-510.998 -149.867)" fill="#1a1818"/>
    </g>
  </g>
</svg>


      </MobileWrapper>
    </LogoWrapper>
  )
}

export default Logo
